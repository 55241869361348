import React from 'react'
import avatar from '../../assets/avtar.png'
import LeaderboardIcon from '../../assets/leaderboard-icon.svg'

function Leaderboard() {
  return (
    <div className="bg-white p-5 mr-0 mb-4 rounded-lg flex flex-col gap-5">
      <h2 className="m-0 text-2xl font-bold uppercase gradient-text roboto-condensed">Leaderboard</h2>
      <table className="leaderboard-table comming-soon">
        <thead>
          <tr>
            <th>Name</th>
            <th>Stage</th>
            <th>Attempts</th>
            <th>Rank</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="flex items-center gap-1">
                <img className="w-9 rounded-full" src={avatar} alt='User Image' /> Jhon Deo
              </span>
            </td>
            <td>12</td>
            <td>50</td>
            <td>#1</td>
          </tr>
          <tr>
            <td>
              <span className="flex items-center gap-1">
                <img className="w-9 rounded-full" src={avatar} alt='User Image' /> Jhon Deo
              </span>
            </td>
            <td>12</td>
            <td>50</td>
            <td>#2</td>
          </tr>
          <tr className="myrank">
            <td>
              <span className="flex items-center gap-1">
                <img className="w-9 rounded-full" src={avatar} alt='User Image' /> Jhon Deo
              </span>
            </td>
            <td>12</td>
            <td>50</td>
            <td>#3</td>
          </tr>
          <tr>
            <td>
              <span className="flex items-center gap-1">
                <img className="w-9 rounded-full" src={avatar} alt='User Image' /> Jhon Deo
              </span>
            </td>
            <td>12</td>
            <td>50</td>
            <td>#4</td>
          </tr>
          <tr>
            <td>
              <span className="flex items-center gap-1">
                <img className="w-9 rounded-full" src={avatar} alt='User Image' /> Jhon Deo
              </span>
            </td>
            <td>12</td>
            <td>50</td>
            <td>#5</td>
          </tr>
        </tbody>
        <div className="comming-cont">
          <img className="w-[200px] h-[200px]" src={LeaderboardIcon} alt='Leaderboard Icon'></img>
          <button className="comming-soon-btn"><span className="roboto-condensed">COMING SOON</span></button>
        </div>
      </table>
    </div>
  )
}

export default Leaderboard