import React from 'react';
import AppleSignInButton from 'react-apple-signin-auth';
import appleIcon from '../assets/apple.svg';
import { socialSignup } from '../redux/action/authAction';
import { useDispatch } from 'react-redux';

function AppleSignup() {
    const dispatch = useDispatch();

    const handleAppleCallback = (response) => {
        console.log('apple_resp', response);

        // Check for user details in the response
        const user = response?.user; // Apple user details, only available the first time
        const authorization = response?.authorization;
        
        if (!authorization || authorization.code === "unknown") {
            console.error('Sorry!', 'Something went wrong with Apple Login.');
            return;
        } else {
            // Payload for social sign up with Apple
            const payload = {
                signupMethod: 'apple',
                socialAuthToken: authorization.code, // Auth token from Apple
                userId: user?.sub, // This would be the Apple ID (sub = subject, Apple's unique identifier)
                userName: user?.name, // User's name (if provided by Apple)
                userEmail: user?.email, // User's email (if provided by Apple)
                ageConsentInfo: {
                    "device": "web"
                },
            };

            dispatch(socialSignup(payload));
        }
    };

    return (
        <div>
            <AppleSignInButton
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                    clientId: 'com.cryptordle.web',
                    scope: 'name email', // Requesting 'name' and 'email' scopes
                    redirectURI: 'https://cryptordle-dev-app.scaleupdevops.in/',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                }}
                uiType="dark"
                className="apple-auth-btn"
                noDefaultStyle={false}
                buttonExtraChildren="Continue with Apple"
                onSuccess={(response) => handleAppleCallback(response)} // Handles the callback on success
                onError={(error) => console.error(error)} // Logs errors
                skipScript={false}
                render={(props) => (
                    <button {...props} className="h-[60px] w-full bg-white rounded-lg border border-[#6A7186] border-solid cursor-pointer">
                        <img src={appleIcon} alt='appleIcon' />
                    </button>
                )}
            />
        </div>
    );
}

export default AppleSignup;
