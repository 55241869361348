import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import SocialRegister from './SocialRegister';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { userSignin } from '../../redux/action/authAction';
import { PulseLoader } from 'react-spinners';

function SignInForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const forgotStep = useSelector((state) => state?.auth?.forgotPasswordStep);

    const userData = useSelector((state) => state?.auth?.userData);
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const [showPassword, setShowPassword] = useState(false);

    const signinUserSchema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email.')
            .required('Email is required.'),
        password: Yup.string()
            .required('Password is required.'),
    })

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        document.title = 'Cryptordle - Sign In';
        if (userData?.isAuth && userData?.isEmailVerified === true) {
            navigate('/dashboard')
        }else if(userData?.isEmailVerified === false) {
            navigate('/email-verify')
        }
    }, [userData?.isAuth, navigate, userData?.isEmailVerified])

    return (
        <div className='pb-4'>
            <Formik
                enableReinitialize
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={signinUserSchema}
                onSubmit={(value, { resetForm }) => {
                    dispatch(userSignin(value))
                    if (forgotStep === 1) {
                        resetForm()
                    }
                }}
            >
                {(formikProps) => {
                    return (
                        <Form>
                            <h2 className='text-center font-bold text-[40px] mb-5 mt-0'>Sign In</h2>
                            <Field className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.email && formikProps.errors.email ? 'is-invalid' : ''}`} name="email" type="email" maxLength="50" placeholder="Email" />
                            <ErrorMessage
                                component="div"
                                name="email"
                                className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                style={{ display: "block" }}
                            />
                            <div className="relative">
                                <Field
                                    className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.password && formikProps.errors.password ? 'is-invalid' : ''
                                        }`}
                                    name="password"
                                    maxLength="50"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                />
                                <span
                                    className="absolute right-4 top-5 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                <ErrorMessage
                                    component="div"
                                    name="password"
                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                    style={{ display: "block" }}
                                />
                            </div>
                            <Row className='items-center mb-4'>
                                <Col className="text-right" span={24}>
                                    <Link className="text-[#ED9E34] text-[16px] font-medium" to={'/forgot-password'}>Forgot Password?</Link>
                                </Col>
                            </Row>
                            <button type="submit" className="h-[55px] w-full px-3 uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)} >{isLoader ? <PulseLoader color="#FFFFFF" /> : "Sign In"}</button>
                        </Form>
                    )
                }}
            </Formik>
            <p className="mb-5 text-center text-[#000000] text-[16px] font-normal">Or sign in using</p>
            <SocialRegister />
            <p className="mb-0 text-center text-[#000000] text-[16px] font-normal">Don’t have an account? <Link className="text-[#ED9E34] text-[16px] font-semibold" to={'/signup'}>Sign up</Link></p>
        </div>
    )
}

export default SignInForm