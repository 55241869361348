import { Modal } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import * as Yup from "yup";
import { changePassword } from '../../redux/action/authAction';

function ChangePassword() {
    const dispatch = useDispatch()
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;

    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const ChangePasswordSchema = Yup.object({

        currentPassword: Yup.string()
            .required('Current password is required.'),

        newPassword: Yup.string()
            .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
            .required('New password is required.'),

        confirmPassword: Yup.string()
            .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
            .required('Confirm new password is required.')
            .oneOf([Yup.ref("newPassword")], "The passwords do not match."),
    })

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const togglePasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const changePasswordHandler = (value) => {
        dispatch(changePassword(value))
        setTimeout(() => {
            setIsModalOpen(false)
        }, 1500)
    };

    return (
        <div>
            <span
                className="absolute right-3 top-5 cursor-pointer text-[#ED9E34] font-normal text-base"
                onClick={showModal}
            >
                {/* {showPassword ? <FaEye /> : <FaEyeSlash />} */}
                Change
            </span>
            <Modal className="change-password custom-input-width" title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        currentPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    }}
                    validationSchema={ChangePasswordSchema}
                    // onSubmit={(value) => changePasswordHandler(value)}
                    onSubmit={(value, { resetForm }) => {
                        changePasswordHandler(value)
                        resetForm()
                      }}
                >
                    {(formikProps) => (
                        <Form>
                            <h2 className="mt-4 mb-5 text-[34px] text-center font-bold uppercase gradient-text-how-to-play roboto-condensed">Change Password</h2>
                            <div className="relative">
                                <Field
                                    className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.currentPassword && formikProps.errors.currentPassword ? 'is-invalid' : ''
                                        }`}
                                    name="currentPassword"
                                    maxLength="50"
                                    type={showCurrentPassword ? "text" : "password"}
                                    placeholder="Current Password"
                                />
                                <span
                                    className="absolute right-4 top-5 cursor-pointer"
                                    onClick={toggleCurrentPasswordVisibility}
                                >
                                    {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                <ErrorMessage
                                    component="div"
                                    name="currentPassword"
                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                    style={{ display: "block" }}
                                />
                            </div>
                            <div className="relative">
                                <Field
                                    className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.newPassword && formikProps.errors.newPassword ? 'is-invalid' : ''
                                        }`}
                                    name="newPassword"
                                    maxLength="50"
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder="New Password"
                                />
                                <span
                                    className="absolute right-4 top-5 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                <ErrorMessage
                                    component="div"
                                    name="newPassword"
                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                    style={{ display: "block" }}
                                />
                            </div>

                            {/* Confirm Password Field */}
                            <div className="relative">
                                <Field
                                    className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.confirmPassword && formikProps.errors.confirmPassword ? 'is-invalid' : ''
                                        }`}
                                    name="confirmPassword"
                                    maxLength="50"
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm New Password"
                                />
                                <span
                                    className="absolute right-4 top-5 cursor-pointer"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                <ErrorMessage
                                    component="div"
                                    name="confirmPassword"
                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                    style={{ display: "block" }}
                                />
                            </div>
                            <button type="submit" className="h-[55px] w-full px-3 uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Update"}</button>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}

export default ChangePassword