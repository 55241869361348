import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { socialSignup, userSignupStatus } from '../../redux/action/authAction';
import GoogleSigninButton from '../../components/GoogleSigninButton.js'
import ReactFacebookLogin from 'react-facebook-login';
import FacebookSignup from '../../components/FacebookSignup.js';
import AppleSignup from '../../components/AppleSignup.js';
import { useNavigate } from 'react-router-dom';

function SocialRegister() {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    // const googleSignup = (payload) => {
    //     dispatch(socialSignup(payload))
    // }
  const isEmailVerified = useSelector((state) => state?.auth?.userData?.user?.isEmailVerified);
  console.log(isEmailVerified, "signupStatus");
  

    const handleFacebookCallback = (response) => {
        if (response?.status === "unknown") {
            console.error('Sorry!', 'Something went wrong with facebook Login.');
         return;
        }
    }

    useEffect(() => {
        if (isEmailVerified) {
          navigate('/dashboard');
          dispatch(userSignupStatus(false))
        }
      }, [isEmailVerified, navigate, dispatch])

  return (
    <div>
        <Row className="mb-5" gutter={15}>
            <Col span={8}>
                <GoogleSigninButton />
            </Col>
            <Col span={8}>
                <FacebookSignup />
            </Col>
            <Col span={8}>
                {/* <Button className="h-[60px] w-full rounded-lg border border-[#6A7186] border-solid">
                    <img src={appleIcon} alt='appleIcon' />
                </Button> */}
                <AppleSignup />
            </Col>
        </Row>
    </div>
  )
}

export default SocialRegister