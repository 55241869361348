import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { emailVerify, emailVerifyOtp, forgotPassword, forgotPasswordOtpVerify, forgotPasswordStep, setErrorMsg } from '../../redux/action/authAction'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Form, Formik } from 'formik'
import OTPInput from 'react-otp-input'
import { PulseLoader } from 'react-spinners'
import * as Yup from "yup";

function Otp() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const forgotPasswordData = useSelector((state) => state?.auth?.forgotPasswordData);
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const errorMsg = useSelector((state) => state?.auth?.errorMsg);
    console.log(forgotPasswordData, "forgotPasswordData");
    

    const forgotStep = useSelector((state) => state?.auth?.forgotPasswordStep);

    const [isClicked, setIsClicked] = useState(false);
    const [timer, setTimer] = useState(60); // Set timer duration in seconds

    const formatTimer = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        dispatch(forgotPasswordStep(0))
    }, [dispatch])

    useEffect(() => {
        document.title = 'Cryptordle - OTP Verify';
        if (forgotStep === 2) {
            navigate('/reset-password');
        }
    }, [forgotStep, navigate])

    const validationSchema = Yup.object().shape({
        otp: Yup.string()
            .trim()
            .required('OTP is required')
            .matches(/^\d{4}$/, 'OTP must be exactly 4 digits'),
    });
    useEffect(() => {
        dispatch(setErrorMsg({
            ...errorMsg,
            errors: errorMsg?.errors?.map((error) =>
                error.path === 'otp' ? { ...error, msg: '' } : error
            ) || []
        }));
        setIsClicked(false);
        setTimer(0);
    }, [])

    const resendOtpHandler = (values) => {
        let otpVerify = {
            email: forgotPasswordData?.data?.email,
        }
        dispatch(forgotPassword(otpVerify))
        console.log(otpVerify, "otpVerifyotpVerifyotpVerify");
        
        if (!isClicked) {
            setIsClicked(true);
            setTimer(30); // Reset the timer to 60 seconds

            console.log('Resending OTP...');

            const countdown = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        setIsClicked(false); // Reset the button state
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    otp: '',
                    email: forgotPasswordData?.data?.email,
                    otpToken: forgotPasswordData?.data?.otpToken,
                    expiresAt: forgotPasswordData?.data?.expiresAt,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    let otpVerify = {
                        email: forgotPasswordData?.data?.email,
                        otp: values.otp,
                        otpToken: forgotPasswordData?.data?.otpToken,
                        expiresAt: forgotPasswordData?.data?.expiresAt,
                    }
                    dispatch(forgotPasswordOtpVerify(otpVerify))
                    if (forgotStep === 2) {
                        resetForm()
                    }

                }}
            >
                {(formikProps) => (
                    <Form className="form-input">
                        <h2 className='text-center font-bold text-[40px] mb-5 mt-0'>Verify OTP</h2>
                        <p className="text-base text-[#000000] font-normal text-center">An OTP has been sent to your provided email address. Please enter the OTP below to continue.</p>
                        <div className="otp_input relative">
                            <OTPInput
                                className="PhoneInputFocus"
                                value={formikProps.values.otp}
                                onChange={(event) => {
                                    formikProps.setFieldValue("otp", event); // Update the form field value
                                }}
                                numInputs={4}
                                renderInput={(props, index) => (<>
                                    <input
                                        {...props}
                                        placeholder='*'
                                        style={{
                                            ...props.style,
                                            borderColor: formikProps.values.otp[index] ? "#CB333B" : "#E0E0E0",
                                            height: "90px",
                                            borderRadius: "8px",
                                            fontSize: "20px",
                                            color: "#6A7186",
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only numerical digits and backspace
                                            if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                            dispatch(setErrorMsg({
                                                ...errorMsg,
                                                errors: errorMsg?.errors?.map((error) =>
                                                    error.path === 'otp' ? { ...error, msg: '' } : error
                                                ) || []
                                            }));

                                        }}
                                    />

                                    {/* <span className='absolute end-0 bottom-[-6px] text-[#CB333B] cursor-pointer' onClick={() => resendOtpHandler()}>
                                        00:08:20 : Resend OTP
                                    </span> */}
                                    <span className='absolute end-0 bottom-[-6px] text-[#ED9E34] cursor-pointer'>
                                        {isClicked ?
                                            <span>00:{formatTimer(timer)}</span>
                                            :
                                            <span onClick={(e) => resendOtpHandler(e.target.value)}>Resend OTP</span>
                                        }
                                    </span>
                                </>
                                )}
                            />
                        </div>
                        <p className='text-red-500 text-[13px] mt-0'>
                            {
                                errorMsg?.length > 0
                                && errorMsg.find((error) => error.path === 'otp')?.msg
                            }
                        </p>

                        <div className="sm:flex">
                            {/* <Link
                            href={"/signup"}
                            className='btn btn-light sm:w-1/2 w-full mr-2 d-inline-md sm:mb-0 mb-3 text-center block'
                        >
                            Back
                        </Link> */}
                            <button type="submit" className="h-[55px] w-full uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Verify"}</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Otp