import React from 'react';
import { Button } from 'antd';
import faceBookIcon from '../assets/facebook.svg';
// import { FacebookLogin } from 'react-facebook-login';
import { socialSignup } from '../redux/action/authAction';
import { useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

function FacebookSignup() {
    const dispatch = useDispatch()
    const handleFacebookCallback = (response) => {
        if (response?.status === "unknown") {
            console.error('Sorry!', 'Something went wrong with facebook Login.');
            return;
        } else {
            const payload = {
                signupMethod: 'facebook',
                socialAuthToken: response?.accessToken,
                ageConsentInfo: {
                    "device": "web"
                },
            }

            dispatch(socialSignup(payload));
        }

    }
    return (
        <div>
            <FacebookLogin
                appId="850838743780462"
                scope='ads_management,email,public_profile'
                // autoLoad
                callback={handleFacebookCallback}
                render={renderProps => (
                    <button onClick={renderProps.onClick}
                        className={`${false && "opacity-40"} h-[60px] w-full bg-white rounded-lg border border-[#6A7186] border-solid cursor-pointer`}>
                        <img src={faceBookIcon} alt='facebookIcon' />
                    </button>
                )}
            />
        </div>
    )
}

export default FacebookSignup