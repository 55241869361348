import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducers from './rootReducers';

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Persisted root reducer
const persistedReducer = persistReducer(persistConfig, rootReducers);

// Initialize Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Initialize store
const initializeStore = () => {
  const middlewares = [sagaMiddleware];

  // Use Redux DevTools only in development mode, otherwise use regular compose
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  // Create store with persisted reducer and middleware
  const reduxStore = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  // Set up Persistor
  const persistor = persistStore(reduxStore);

  // Run root Saga
  sagaMiddleware.run(rootSaga);

  return { reduxStore, persistor };
};

// Initialize store and persistor
const { reduxStore, persistor } = initializeStore();

export { reduxStore as store, persistor };
