import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Auth from '../container/auth/Auth';
import SignInForm from '../container/auth/SignInForm';
import SignUpForm from '../container/auth/SignUpForm';
import ForgotPassword from '../container/auth/ForgotPassword';
import ResetPassword from '../container/auth/ResetPassword';
import Otp from '../container/auth/Otp';
import Dashboard from '../../src/container/pages/Dashboard'
import Profile from '../container/pages/Profile';
import AuthGuard from "../utils/AuthGuard"
import EmailVerify from '../container/pages/EmailVerify';
import EmailVerifyOtp from '../container/auth/EmailVerifyOtp';

export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Auth />}>
            <Route path="/" element={<SignInForm />} />
            <Route path="signup" element={<SignUpForm />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="otp" element={<Otp />} />
            <Route path="email-verify-otp" element={<EmailVerifyOtp />} />
            <Route path="email-verify" element={<AuthGuard><EmailVerify /></AuthGuard>} />
          </Route>
          <Route path="dashboard" element={<AuthGuard><Dashboard /></AuthGuard>} />
          <Route path="profile" element={<AuthGuard><Profile /></AuthGuard>} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
