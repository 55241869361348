import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { socialSignup } from '../redux/action/authAction';
import { Button } from 'antd';
import googleIcon from '../assets/google.svg';

const GoogleSigninButton = ({ signup_source }) => {
    const dispatch = useDispatch();
    const [googleTokenResponse, setGoogleTokenResponse] = useState({});
    const googleLogin = useGoogleLogin({
        onSuccess: credentialResponse => setGoogleTokenResponse(credentialResponse),
    });
    useEffect(() => {
        if(googleTokenResponse?.access_token){
            const payload = {
                signupMethod:'google',
                socialAuthToken:googleTokenResponse?.access_token,
                ageConsentInfo: {
                    "device": "web"
                },
            }
            dispatch(socialSignup(payload));
        }
    }, [dispatch, googleTokenResponse, signup_source])
    return (
        <>
            <Button
                onClick={() => googleLogin()}
                className={`${false && "opacity-40"} h-[60px] w-full bg-white rounded-lg border border-[#6A7186] border-solid `}>
                    <img src={googleIcon} alt='googleIcon' />
            </Button>
        </>
    )
}
export default GoogleSigninButton