import { Button, Drawer, Space, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import playScreenIcon from '../../assets/play-screen-icon.svg'
import RulesTabs from './RulesTabs';
import { gameRules } from '../../redux/action/authAction';
import { useDispatch, useSelector } from 'react-redux';

function SideDrawer() {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('right');
    const showDrawer = () => {
        setOpen(true);
        const payload = {
            game_type: 'prize_web',
            topic: '',
        }
        dispatch(gameRules(payload))
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Tooltip color={'#333'} placement="bottom" title="How to Play">
                <Link onClick={showDrawer}>
                    <img className="w-9" src={playScreenIcon} alt='Play Screen Icon'></img>
                </Link>
            </Tooltip>
            <Drawer
                title=""
                placement={placement}
                width={500}
                onClose={onClose}
                open={open}
                className="!bg-[#F1F5F9]"
            // extra={
            //     <Space>
            //         <Button onClick={onClose}>Cancel</Button>
            //         <Button type="primary" onClick={onClose}>
            //             OK
            //         </Button>
            //     </Space>
            // }
            >
                <h2 className="game-guidline-gradient text-4xl roboto-condensed text-center mt-0">GAME GUIDELINES</h2>
                <RulesTabs />
            </Drawer>
        </div>
    )
}

export default SideDrawer