import React from 'react';
import { Col, Row } from 'antd';
import leftImg from '../../assets/login-img.svg';
import logo from '../../assets/cryptordle-logo.svg';
import { Outlet } from 'react-router-dom';
import BackButton from '../../components/back-button/BackButton'

function Auth() {
  return (
    <div className="h-[100vh] w-full bg-[url('/src/assets/bg-grid-img.svg')] bg-cover overflow-hidden">
      {/* <BackButton/> */}
      <Row className="items-center h-full">
        <Col className="h-[100vh] bg-[#0c696ae0] hidden md:block" span={12}>
          <img className="w-full h-full object-cover" src={leftImg} alt='login-cover-photo'/>
        </Col>
        <Col className="py-10 px-10 sm:px-5 md:px-12 lg:px-24 xl:px-32 overflow-hidden overflow-y-auto max-h-[100vh]" span={12} xs={24} md={12}>
          <div className="w-full custom-input-width">
            <div className="text-center">
              <img className="w-[215px] mb-5" src={logo} alt='login-cover-photo'/>
            </div>
            <Outlet />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Auth