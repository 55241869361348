import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { emailVerifyOtp, forgotPasswordOtpVerify, forgotPasswordStep, setErrorMsg } from '../../redux/action/authAction'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Form, Formik } from 'formik'
import OTPInput from 'react-otp-input'
import { PulseLoader } from 'react-spinners'
import * as Yup from "yup";

function EmailVerifyOtp() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData = useSelector((state) => state?.auth?.userData);
    const emailVerifyOtpData = useSelector((state) => state?.auth.emailVerifyData);
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const errorMsg = useSelector((state) => state?.auth?.errorMsg);

    const isEmailVerified = useSelector((state) => state?.auth?.emailVerifyOtpData?.user?.isEmailVerified);
    useEffect(() => {
        document.title = 'Cryptordle - Email Verifiy OTP';
        if (isEmailVerified === true) {
            navigate('/dashboard');
        }
    }, [isEmailVerified, navigate])

    const validationSchema = Yup.object().shape({
        otp: Yup.string()
            .trim()
            .required('OTP is required')
            .matches(/^\d{4}$/, 'OTP must be exactly 4 digits'),
    });
    useEffect(() => {
        dispatch(setErrorMsg({
            ...errorMsg,
            errors: errorMsg?.errors?.map((error) =>
                error.path === 'otp' ? { ...error, msg: '' } : error
            ) || []
        }));
    }, [])

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    otp: '',
                    email: emailVerifyOtpData?.email,
                    otpToken: emailVerifyOtpData?.otpToken,
                    expiresAt: emailVerifyOtpData?.expiresAt,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    let otpVerify = {
                        email: emailVerifyOtpData?.email,
                        otp: values.otp,
                        otpToken: emailVerifyOtpData?.otpToken,
                        expiresAt: emailVerifyOtpData?.expiresAt,
                    }
                    dispatch(emailVerifyOtp(otpVerify))
                }}
            >
                {(formikProps) => (
                    <Form className="form-input">
                        <h2 className='text-center font-bold text-[40px] mb-5 mt-0'>Email Verify OTP</h2>
                        <p className="text-base text-[#000000] font-normal text-center">An OTP has been sent to your provided email address. Please enter the OTP below to continue.</p>
                        <div className="otp_input relative">
                            <OTPInput
                                value={formikProps.values.otp}
                                onChange={(event) => {
                                    formikProps.setFieldValue("otp", event); // Update the form field value
                                }}
                                numInputs={4}
                                renderInput={(props, index) => (<>
                                    <input
                                        {...props}
                                        placeholder='*'
                                        style={{
                                            ...props.style,
                                            borderColor: formikProps.values.otp[index] ? "#CB333B" : "#E0E0E0",
                                            height: "90px",
                                            borderRadius: "16px",
                                            fontSize: "20px",
                                            color: "#6A7186",
                                        }}
                                        onKeyPress={(e) => {
                                            // Allow only numerical digits and backspace
                                            if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                            dispatch(setErrorMsg({
                                                ...errorMsg,
                                                errors: errorMsg?.errors?.map((error) =>
                                                    error.path === 'otp' ? { ...error, msg: '' } : error
                                                ) || []
                                            }));

                                        }}
                                    />

                                    {/* <span className='absolute end-0 bottom-[-25px] text-[#CB333B] cursor-pointer' onClick={() => resendOtpHandler()}>
                                        {isClicked ?
                                            <p>00:{formatTimer(timer)}</p>
                                            :
                                            "Resend OTP"
                                        }
                                    </span> */}
                                </>
                                )}
                            />
                        </div>
                        <p className='text-red-500 text-[13px] mt-0'>
                            {
                                errorMsg?.length > 0
                                && errorMsg.find((error) => error.path === 'otp')?.msg
                            }
                        </p>

                        <div className="sm:flex">
                            {/* <Link
                            href={"/signup"}
                            className='btn btn-light sm:w-1/2 w-full mr-2 d-inline-md sm:mb-0 mb-3 text-center block'
                        >
                            Back
                        </Link> */}
                            <button type="submit" className="h-[55px] w-full uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Verify"}</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default EmailVerifyOtp