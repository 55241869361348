import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import PracticeHowToPlay from '../rules/PracticeHowToPlay';
import PracticeGameRules from '../rules/PracticeGameRules';
import PracticeGameFairness from '../rules/PracticeGameFairness';
import { useDispatch, useSelector } from 'react-redux';
import { gameRules } from '../../redux/action/authAction';
import { BeatLoader, PulseLoader } from 'react-spinners';

function RulesTabs() {
    const gameRulesData = useSelector((state) => state?.auth?.gameRulesData?.rules);
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    console.log(gameRulesData, "Game Rules Data Rules");

    const onChange = (key) => {
        console.log(key);
    };

    const renderTabItems = () => {
        let items = [];

        gameRulesData?.map((item, index) => (
            items.push(
                {
                    key: item.id,
                    label: item.title,
                    children: <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                }
            )
        ))
        return items;
    }



    return (
        <div>
            {isLoader ? <BeatLoader color="#000" className='text-center' /> :
                <Tabs className='game-guide-tab' defaultActiveKey="1" items={renderTabItems()} onChange={onChange} />
            }
        </div>
    );
}

export default RulesTabs;
