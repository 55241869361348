import { combineReducers } from 'redux';

import { userReducer } from './reducers/userReducer';

const appReducers = combineReducers({
  auth:userReducer
})

const rootReducers = (state, action) => {
    if(action.type === 'RESET_ALL_DATA') {
        return appReducers([], action)
    }
    return appReducers(state, action)
}

export default rootReducers;
