import { Card, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../assets/cryptordle-logo.svg';
import img from '../../assets/get-app.svg'
import verifyEmail from '../../assets/verify-email.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { emailVerify, setEmailVerifyStatus } from '../../redux/action/authAction';

function EmailVerify() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const emailVerifyStatus = useSelector((state) => state?.auth.emailVerifyStatus);

    const verifyEmailHandler = () => {
        dispatch(emailVerify())
    }

    useEffect(() => {
        document.title = 'Cryptordle - Email Verify';
     if(emailVerifyStatus){
        navigate('/email-verify-otp');
        dispatch(setEmailVerifyStatus(false))
     }
    }, [dispatch, emailVerifyStatus, navigate])
    
    return (
        <div>
            <Row className="">
                <Col span={12} xs={24} md={24} lg={24} className="text-center">
                    <img className="w-full md:w-[250px] m-auto" src={verifyEmail} alt='Verify Email Img'></img>
                    <h3 className="text-4xl m-0 text-center roboto-condensed">Please verify your email address</h3>
                    <p className="text-base mb-5 text-center roboto-condensed">Please verify this email address by clicking button below.</p>
                    <div className="flex gap-8 justify-center">
                        <Link>
                            <button onClick={() => {verifyEmailHandler()}} type="submit" className="h-[55px] w-full px-3 uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer"> Verify your Email</button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EmailVerify