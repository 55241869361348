import { Button, Checkbox, Col, Input, Row } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { forgotPassword, forgotPasswordStep, setForgotPassword } from '../../redux/action/authAction';
import { PulseLoader } from 'react-spinners';

function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const forgotPasswordData = useSelector((state) => state?.auth?.forgotPasswordData);
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const forgotStep = useSelector((state) => state?.auth?.forgotPasswordStep);

    useEffect(() => {
        document.title = 'Cryptordle - Forgot Password';
        if (forgotStep === 1) {
            navigate('/otp');
            dispatch(setForgotPassword({...forgotPasswordData, success: false}))
          // Redirect after saga has successfully completed
        }
      }, [dispatch, forgotStep]);

      useEffect(() => {
       dispatch(forgotPasswordStep(0))
      }, [dispatch])
      

    const createUserSchema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email.')
            .required('Email is required.'),
    })
    return (
        <div>
            <Formik
                initialValues={{
                    email: ""
                }}
                validationSchema={createUserSchema}
                // onSubmit={(value) => console.log(value)}
                onSubmit={(value, { resetForm }) => {
                    dispatch(forgotPassword(value))
                    if(forgotStep === 1){
                        resetForm()
                    }
                }}
            >
                {(formikProps) => (
                    <Form>
                        <h2 className='text-center font-bold text-[40px] mb-5 mt-0'>Forgot Password</h2>
                        <p className="w-full text-[#000000] text-[16px] font-normal text-center">Enter your registered email ID to receive an OTP for reset your password.</p>
                        {/* <Input className="h-[55px] border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal" placeholder="Email" /> */}
                        <Field className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.email && formikProps.errors.email ? 'is-invalid' : ''}`} name="email" type="email" maxLength="50" placeholder="Email" />
                        <ErrorMessage
                            component="div"
                            name="email"
                            className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                            style={{ display: "block" }}
                        />
                        <button type="submit" className="h-[55px] w-full uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Send"}</button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ForgotPassword