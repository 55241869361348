import { APP_SETTING, CHANGE_PASSWORD, DELETE_PROFILE_PIC, EMAIL_VERIFY, EMAIL_VERIFY_OTP, EMAIL_VERIFY_STATUS, FORGOT_PASSWORD, FORGOT_PASSWORD_OTP_VERIFY, FORGOT_PASSWORD_STEP, GAME_RULES, LOGOUT, PROFILE_LOADER, RESET_PASSWORD, SET_APP_SETTING, SET_EMAIL_VERIFY, SET_EMAIL_VERIFY_OTP, SET_ERROR_MSG, SET_FORGOT_PASSWORD, SET_GAME_RULES, SET_LOADER, SET_RESET_PASSWORD, SET_UPDATE_PROFILE, SET_USER_DATA, SET_USER_PROFILE, SOCIAL_SIGNUP, UPDATE_PROFILE, UPLOAD_PROFILE_PIC, USER_PROFILE, USER_SIGNIN, USER_SIGNUP, USER_SIGNUP_STATUS } from "../action-types/authActionTypes"

export const userSignin = (payload) => {
    return {
        type: USER_SIGNIN,
        payload
    }
}
export const setLoader = (payload) => {
    return {
        type: SET_LOADER,
        payload
    }
}
export const profileLoader = (payload) => {
    return {
        type: PROFILE_LOADER,
        payload
    }
}

export const userSignup = (payload) => {
    return {
        type: USER_SIGNUP,
        payload
    }
}

export const userSignupStatus = (payload) => {
    return {
        type: USER_SIGNUP_STATUS,
        payload
    }
}

export const userProfile = () => {
    return {
        type: USER_PROFILE,
    }
}

export const appSetting = (payload) => {
    return {
        type: APP_SETTING,
        payload
    }
}

export const setAppSetting = (payload) => {
    return {
        type: SET_APP_SETTING,
        payload
    }
}

export const gameRules = (payload) => {
    return {
        type: GAME_RULES,
        payload
    }
}

export const setGameRules = (payload) => {
    return {
        type: SET_GAME_RULES,
        payload
    }
}

export const updateProfile = (payload) => {
    return {
        type: UPDATE_PROFILE,
        payload
    }
}

export const setUpdateProfile = (payload) => {
    return {
        type: SET_UPDATE_PROFILE,
        payload
    }
}

export const uploadProfilePic = (payload) => {
    return {
        type: UPLOAD_PROFILE_PIC,
        payload
    }
}

export const deleteProfilePic = (payload) => {
    return {
        type: DELETE_PROFILE_PIC,
        payload
    }
}

// export const setUserProfile = (payload) => {
//     return {
//         type: SET_USER_PROFILE,
//         payload
//     }
// }

export const setUserdata = (payload) => {
    return {
        type: SET_USER_DATA,
        payload
    }
}

export const socialSignup = (payload) => {
    return {
        type: SOCIAL_SIGNUP,
        payload
    }
}

export const forgotPassword = (payload) => {
    return {
        type: FORGOT_PASSWORD,
        payload
    }
}
export const forgotPasswordStep = (payload) => {
    return {
        type: FORGOT_PASSWORD_STEP,
        payload
    }
}
export const forgotPasswordOtpVerify = (payload) => {
    return {
        type: FORGOT_PASSWORD_OTP_VERIFY,
        payload
    }
}
export const setErrorMsg = (payload) => {
    return {
        type: SET_ERROR_MSG,
        payload
    }
}

export const setForgotPassword = (payload) => {
    return {
        type: SET_FORGOT_PASSWORD,
        payload
    }
}

export const resetPassword = (payload) => {
    return {
        type: RESET_PASSWORD,
        payload
    }
}

export const setResetPassword = (payload) => {
    return {
        type: SET_RESET_PASSWORD,
        payload
    }
}

export const changePassword = (payload) => {
    return {
        type: CHANGE_PASSWORD,
        payload
    }
}

export const emailVerify = () => {
    return {
        type: EMAIL_VERIFY,
    }
}

export const setEmailVerify = (payload) => {
    return {
        type: SET_EMAIL_VERIFY,
        payload
    }
}

export const setEmailVerifyStatus = (payload) => {
    return {
        type: EMAIL_VERIFY_STATUS,
        payload
    }
}

export const emailVerifyOtp = (payload) => {
    return {
        type: EMAIL_VERIFY_OTP,
        payload
    }
}

export const setVerifyEmailOtp = (payload) => {
    return {
        type: SET_EMAIL_VERIFY_OTP,
        payload
    }
}

export const logOut = () => {
    return {
        type: LOGOUT
    }
}