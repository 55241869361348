import { all, call } from 'redux-saga/effects';
import authSaga from './handlers/authHandlers';
 


export default function* rootSaga() {
    yield all([
        call(authSaga),
    ]);
}
