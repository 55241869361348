import React from 'react'
import Header from './header/Header';

const Layout = ({ children }) => {
    return (
        <div className="bg-[#F1F5F9]">
            {/* Header */}
            <Header></Header>
            {/* Main Content */}
            <main className="px-[30px]">
                {children}
            </main>
            {/* Footer */}
            <footer className="bg-[#F1F5F9] text-[#333333] px-[30px] py-5 text-center text-[#333333]">
                &copy;Cryptordle&trade; {(new Date().getFullYear() === 2024) ?  '2024' : (new Date().getFullYear())}
            </footer>
        </div>
    );
};
export default Layout;
