import { Dropdown, Space, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import logo from '../../assets/cryptordle-logo.svg'
import profile from '../../assets/profile.png'
import logout from '../../assets/logout.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logOut, userProfile } from '../../redux/action/authAction'
import SideDrawer from '../side-drawer/SideDrawer'
import HelpAndSupport from '../side-drawer/HelpAndSupport'

function Header() {
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state?.auth?.userData);
    const auth = useSelector((state) => state?.auth?.userData?.isAuth);
    const { profileBg, profileInitials, profilePic } = useSelector((state) => state?.auth?.userData || {});

    const logoutfun = (payload) => {
        dispatch(logOut(payload));
    }
    useEffect(() => {
        if (auth) {
            dispatch(userProfile())
        }
    }, [auth, dispatch])

    const items = [

        {
            label: <Link className="text-base font-medium flex items-center gap-2 justify-start" to="/profile"><img className="w-4" src={profile} alt='profile Icon'></img> Profile</Link>,
            key: '0',
        },
        {
            label: <Link className="text-base font-medium flex items-center gap-2 justify-start" onClick={logoutfun} ><img className="w-4" src={logout} alt='logout Icon'></img> Logout</Link>,
            key: '1',
        },

    ];
    return (
        <div className="bg-[#F1F5F9] px-[30px] py-[20px] flex items-center justify-between">
            <div className="logo-sec">
                <Link to={'/'}>
                    <img className='w-32' src={logo} alt='cryptordle-logo'></img>
                </Link>
            </div>
            <div className="flex items-center justify-end gap-4">
                <SideDrawer />
                <HelpAndSupport />
                <div className='cursor-pointer'>
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <span onClick={(e) => e.preventDefault()}>
                            <Space className={`bg-gradient-to-r from-slate-50 to-slate-200 rounded-full w-14 h-14 overflow-hidden flex justify-center items-center`}>
                                {profileData?.profilePic ? (
                                    <img className="object-cover w-full h-full rounded-full" src={profileData?.profilePic} alt="Profile Img" />
                                ) : (
                                    <span className="font-medium text-lg flex justify-center items-center">{profileData?.profileInitials}</span>
                                )}
                            </Space>
                        </span>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default Header