import { Button, Checkbox, Col, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../redux/action/authAction';
import { PulseLoader } from 'react-spinners';

function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;

    const forgotPasswordData = useSelector((state) => state?.auth?.forgotPasswordData);
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const forgotStep = useSelector((state) => state?.auth?.forgotPasswordStep);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const createUserSchema = Yup.object({

        password: Yup.string()
        .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
            .required('Password is required.'),

        confirmPassword: Yup.string()
        .matches(passwordRegex, 'Password must be at least 8 characters long and include at least 1 digit, 1 uppercase, 1 lowercase character and 1 special character.')
            .required('Confirm password is required.')
            .oneOf([Yup.ref("password")], "The passwords do not match."),
    })

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        document.title = 'Cryptordle - Reset Password';
        if (forgotStep === 3) {
            navigate('/');
        }
    }, [forgotStep, navigate])

    return (
        <div>
            {/* <Input className="h-[55px] border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal" placeholder="New Password" />
            <Input className="h-[55px] border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal" placeholder="Re-type New Password" />
            <Button className="h-[55px] w-full uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34]">Reset</Button> */}
            <div className='pb-5'>
                <Formik
                    enableReinitialize
                    initialValues={{
                        email: forgotPasswordData.data.user.email,
                        password: "",
                        confirmPassword: "",
                        passwordResetToken: forgotPasswordData.data.passwordResetToken,
                    }}
                    validationSchema={createUserSchema}
                    onSubmit={(value, { resetForm }) => {
                        dispatch(resetPassword(value))
                        resetForm()
                    }}
                >
                    {(formikProps) => (
                        <Form>
                            <h2 className='text-center font-bold text-[40px] mb-5 mt-0'>Reset Password</h2>
                            <p className="text-base text-[#000000] font-normal text-center">Enter your registered mail ID to receive an email with a link to reset the password</p>
                            <div className="relative">
                                <Field
                                    className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.password && formikProps.errors.password ? 'is-invalid' : ''
                                        }`}
                                    name="password"
                                    maxLength="50"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                />
                                <span
                                    className="absolute right-4 top-5 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                <ErrorMessage
                                    component="div"
                                    name="password"
                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                    style={{ display: "block" }}
                                />
                            </div>

                            {/* Confirm Password Field */}
                            <div className="relative">
                                <Field
                                    className={`h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal ${formikProps.touched.confirmPassword && formikProps.errors.confirmPassword ? 'is-invalid' : ''
                                        }`}
                                    name="confirmPassword"
                                    maxLength="50"
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                />
                                <span
                                    className="absolute right-4 top-5 cursor-pointer"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                                <ErrorMessage
                                    component="div"
                                    name="confirmPassword"
                                    className="invalid-feedback py-1 -mt-4 mb-1 text-sm text-red-500"
                                    style={{ display: "block" }}
                                />
                            </div>
                            <button type="submit" className="h-[55px] w-full px-3 uppercase bg-[#000000] rounded-lg text-white font-bold text-[20px] border-none hover:!text-white hover:!bg-gradient-to-l hover:from-[#6AD2D4] hover:to-[#ED9E34] cursor-pointer" disabled={!(formikProps.isValid && formikProps.dirty)}>{isLoader ? <PulseLoader color="#FFFFFF" /> : "Reset"}</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassword