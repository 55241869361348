import { Button } from 'antd';
import React, { useCallback, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';

function ReferralCodeCopy() {
    const [value, setValue] = useState("game.mydomain.com/login");
    const [copied, setCopied] = useState(false);
    const onChange = useCallback(({ target: { value } }) => {
        setValue(value);
    }, []);
    const onClick = useCallback(({ target: { innerText } }) => {
        console.log(`Clicked on "${innerText}"!`);
    }, []);
    const onCopy = useCallback(() => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000)
    }, []);

    // use

    return (
        <div className="app">
            <div className="relative custom-wrapper w-full mt-[1.25rem]">
                <input
                    id="search"
                    maxLength={50}
                    className="custom-input w-full !pr-24 md:!pr-[120px] focus:outline-none bg-white"
                    onChange={onChange} 
                    value={value}
                    disabled
                />
                <CopyToClipboard onCopy={onCopy} text={value}>
                    <button onClick={onClick} type="button" className="custom-button px-6 py-2 lg:w-auto flex items-center justify-center lg:h-[48px] lg:min-w-[100px] h-12 lg:px-6 lg:py-[10px] absolute end-[6px] bg-gradient-to-r from-[#6AD2D4] to-[#ED9E34] font-inter">
                        <FaCopy className="mr-0 md:mr-1"/> <span className="hidden md:block">Copy</span>
                    </button>
                </CopyToClipboard>
            </div>
            <section className="section mt-2">
                {copied ? <span style={{ color: "#ED9E34" }}>Copied Successfully.</span> : null}
            </section>
        </div>
    )
}

export default ReferralCodeCopy