import { call, delay, put, takeLatest } from "redux-saga/effects";
import { toast } from 'react-toastify';
import { appSettingApi, changePasswordApi, deleteProfilePicApi, emailVerifyApi, emailVerifyOtpApi, forgotPasswordApi, forgotPassworOtpVerifydApi, gameRulesApi, logOutApi, profileApi, resetPasswordApi, signinApi, signupApi, socialSignupApi, updateProfileApi, uploadProfilePicApi } from "../requests/userRequest";
import { APP_SETTING, CHANGE_PASSWORD, DELETE_PROFILE_PIC, EMAIL_VERIFY, EMAIL_VERIFY_OTP, FORGOT_PASSWORD, FORGOT_PASSWORD_OTP_VERIFY, GAME_RULES, LOGOUT, RESET_PASSWORD, SOCIAL_SIGNUP, UPDATE_PROFILE, UPLOAD_PROFILE_PIC, USER_PROFILE, USER_SIGNIN, USER_SIGNUP } from "../../action-types/authActionTypes";
import { emailVerify, forgotPasswordStep, profileLoader, setAppSetting, setEmailVerify, setEmailVerifyStatus, setErrorMsg, setForgotPassword, setGameRules, setLoader, setResetPassword, setUpdateProfile, setUserdata, setUserProfile, setVerifyEmailOtp, userSignupStatus } from "../../action/authAction";
import api from "../../../utils/api";

function* userSigninHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(signinApi, payload.payload);
    if (data) {
      // yield put(setUserList(data?.data))
      yield localStorage.setItem("access_token", data?.data?.token);
      yield (api.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      });
      let userDataPaylod = {
        ...data.data.user,
        isAuth: true
      }
      yield put(setUserdata(userDataPaylod))
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* userSignupHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(signupApi, payload.payload);
    if (data) {
      // yield put(setUserList(data?.data))
      yield localStorage.setItem("access_token", data?.data?.token);
      yield (api.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      });
      yield put(userSignupStatus(true))
      yield put(emailVerify())
      let userDataPaylod = {
        ...data.data.user,
        isAuth: true
      }
      yield put(setUserdata(userDataPaylod))
      // toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* appSettingHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(appSettingApi, payload.payload);
    if (data) {
      yield put(setAppSetting(data.data))
      // toast.success(data?.message);
    }
  }
  catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* gameRulesHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(gameRulesApi, payload.payload);
    if (data) {
      let gameRulesDataPayload = {
        ...data.data,
      }
      yield put(setGameRules(gameRulesDataPayload))
      // toast.success(data?.message);
    }
  }
  catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* userProfileHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(profileApi, payload.payload);
    if (data) {
      let userProfileDataPayload = {
        ...data.data.user,
        isAuth: true
      }
      yield put(setUserdata(userProfileDataPayload))
      // toast.success(data?.message);
    }
  }
  catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* updateProfileHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(updateProfileApi, payload.payload);
    if (data) {
      let userProfileDataPayload = {
        ...data.data.user,
        payload
      }
      yield put(setUpdateProfile(userProfileDataPayload))
      toast.success(data?.message);
      window.location.reload();
    }
  }
  catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* uploadProfilePicHandler(payload) {
  yield put(profileLoader(true))
  try {
    const { data } = yield call(uploadProfilePicApi, payload.payload);
    if (data) {
      let userProfilePicPayload = {
        ...data.data,
        isAuth: true,
      }
      yield put(setUserdata(userProfilePicPayload))
      toast.success(data?.message);
    }
  }
  catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
    yield put(profileLoader(false))
  }
}

function* deleteProfilePicHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(deleteProfilePicApi, payload.payload);
    if (data) {
      let userProfilePicPayload = {
        ...data.data,
        isAuth: true
      }
      yield put(setUserdata(userProfilePicPayload))

      toast.success(data?.message);
    }
  }
  catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* socialSignupHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(socialSignupApi, payload.payload);
    if (data) {
      // yield put(setUserList(data?.data))
      yield localStorage.setItem("access_token", data?.data?.token);
      yield (api.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      });
      // yield put(emailVerify())
      let userDataPaylod = {
        ...data.data,
        isAuth: true
      }
      yield put(setUserdata(userDataPaylod))
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* forgotPasswordHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(forgotPasswordApi, payload.payload);
    if (data) {
      yield put(setForgotPassword(data))
      yield put(forgotPasswordStep(1))
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* forgotPasswordOtpVerifyHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(forgotPassworOtpVerifydApi, payload.payload);
    if (data) {
      yield put(setForgotPassword(data))
      yield put(forgotPasswordStep(2))
      toast.success(data?.message);
    } else {
      yield put(setErrorMsg(data.errors))
    }
  }
  catch (error) {
    yield put(setErrorMsg(error?.response?.data?.errors))
  } finally {
    yield put(setLoader(false))
  }
}

function* resetPasswordHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(resetPasswordApi, payload.payload);
    if (data) {
     
     
      yield put(forgotPasswordStep(3))
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* changePasswordHandler(payload) {
  yield put(setLoader(true))
  try {
    const { data } = yield call(changePasswordApi, payload.payload);
    if (data) {
      yield localStorage.clear("access_token");

      let userDataPaylod = {      
        isAuth: false
      }
      yield put(setUserdata(userDataPaylod))
      
     // window.location.reload();
      console.log("cp_msg",data?.message);
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setLoader(false))
  }
}

function* emailVerifyHandler() {
  try {
    const { data } = yield call(emailVerifyApi);
    if (data) {
      yield put(setEmailVerifyStatus(true))
      yield put(setEmailVerify(data.data))
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  }
}

function* verifyEmailOtpHandler(payload) {
  try {
    const { data } = yield call(emailVerifyOtpApi, payload.payload);
    if (data) {
       yield put(setVerifyEmailOtp(data?.data))
      toast.success(data?.message);
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  }
}

function* logOutHandler(payload) {

  try {
    const { data } = yield call(logOutApi, payload.payload);
    if (data) {
      yield localStorage.clear("access_token");
      toast.success(data?.message);
      window.location.reload();
    }
  }
  catch (error) {
    toast.error(error?.response?.data?.message);
  }

}

export default function* authSaga() {
  yield takeLatest(USER_SIGNUP, userSignupHandler);
  yield takeLatest(APP_SETTING, appSettingHandler);
  yield takeLatest(GAME_RULES, gameRulesHandler);
  yield takeLatest(USER_PROFILE, userProfileHandler);
  yield takeLatest(UPDATE_PROFILE, updateProfileHandler);
  yield takeLatest(UPLOAD_PROFILE_PIC, uploadProfilePicHandler);
  yield takeLatest(DELETE_PROFILE_PIC, deleteProfilePicHandler);
  yield takeLatest(SOCIAL_SIGNUP, socialSignupHandler);
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordHandler);
  yield takeLatest(FORGOT_PASSWORD_OTP_VERIFY, forgotPasswordOtpVerifyHandler);
  yield takeLatest(RESET_PASSWORD, resetPasswordHandler);
  yield takeLatest(USER_SIGNIN, userSigninHandler);
  yield takeLatest(EMAIL_VERIFY, emailVerifyHandler);
  yield takeLatest(CHANGE_PASSWORD, changePasswordHandler);
  yield takeLatest(EMAIL_VERIFY_OTP, verifyEmailOtpHandler);
  yield takeLatest(LOGOUT, logOutHandler);
}