import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
    const navigation = useNavigate();
    const location = useLocation();
    const userData = useSelector((state) => state?.auth?.userData);
    
    // Array of public URLs that don't require authentication
    const publicUrls = ["/signup", "/forgot-password", "/otp", "/reset-password"];  // Add more public routes as needed

    useEffect(() => {
        const currentPath = location.pathname;

        // Check if the current path is a public URL
        const isPublicUrl = publicUrls.includes(currentPath);

        // If the user is not authenticated and trying to access a protected route
        if (!localStorage.getItem("access_token") && !userData?.auth && !isPublicUrl) {
            navigation("/");  // Redirect to login or home page if unauthenticated
        }

        // If the user is authenticated and trying to access a public route (e.g., signup), redirect to home
        if (localStorage.getItem("access_token") && userData?.auth && isPublicUrl) {
            navigation("/dashboard");  // Redirect to the authenticated home or dashboard page
        }
    }, [navigation, userData, location]);

    return <>{children}</>;
};

export default AuthGuard;
